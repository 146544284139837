<template>
  <div class="approve-container">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnHome">
        <div></div>
        <span>军人认证</span>
      </div>
      <div class="approve-content">
        <div class="personal-content">
          <img :class="isclass" v-if="userInfo" :src="userInfo.avatar" alt="" />
          <span> {{ newname }}</span>
        </div>
        <van-form class="van-form" @submit="onSubmit">
          <van-field
            v-if="!approve"
            v-model="identity"
            required
            name="身份证"
            label="身份证"
            placeholder="请输入身份证"
            :rules="identityVerify"
          />
          <van-field
            v-else
            v-model="identity"
            name="身份证"
            label="身份证"
            placeholder="请输入身份证"
            disabled
          />

          <div style="margin: 16px">
            <van-button
              v-if="!approve"
              round
              block
              type="primary"
              native-type="submit"
              >认证</van-button
            >
            <van-button v-else round block type="primary">已认证</van-button>
          </div>
        </van-form>
      </div>
    </layout>
  </div>
</template>

<script>
import { soldierApprove, SoldiersCertification } from '@/api/me'
import { getItem, setItem } from '@/utils/auth'
import layout from '@/components/layout.vue'
export default {
  name: '',
  components: {
    layout
  },
  data() {
    return {
      name: '测试名',
      approve: false,
      identity: '',
      isSucceed: 3,
      identityVerify: [
        {
          required: true,
          message: '请填写身份证'
        },
        {
          pattern:
            /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
          message: '身份证格式错误'
        }
      ]
    }
  },
  computed: {
    isclass() {
      if (this.isSucceed == 3 && !this.approve) {
        return
      } else if (this.isSucceed == 1 || this.approve) {
        return 'succeed'
      } else {
        return 'error'
      }
    },
    newname() {
      if (getItem('userInfo')) {
        if (!this.approve) {
          if (getItem('userInfo').realname.length == 2) {
            return `${getItem('userInfo').realname.substr(0, 1)}*`
          } else if (getItem('userInfo').realname.length == 3) {
            return `${getItem('userInfo').realname.substr(0, 1)}**`
          } else if (getItem('userInfo').realname.length == 4) {
            return `${getItem('userInfo').realname.substr(0, 1)}${getItem(
              'userInfo'
            ).realname.substr(1, 1)}**`
          }
        } else {
          return getItem('userInfo').realname
        }
      }
    },
    userInfo() {
      return getItem('userInfo')
    }
  },
  watch: {
    userInfo: {
      handler(val, oldVal) {
        SoldiersCertification({
          userId: getItem('userInfo').id
        }).then((res) => {
          if (res.code == 200) {
            console.log(res)
            this.approve = res.result
            this.identity = res.result?.card
            this.$store.commit('user/setSoldierApprove', res.result)
          }
        })
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    onSubmit() {
      if (!this.approve) {
        soldierApprove({
          idCard: this.identity,
          userId: getItem('userInfo').id
        }).then(async (res) => {
          if (res.result == null) {
            this.isSucceed = 0
            this.$toast.fail(res.message)
          } else {
            this.isSucceed = 1
            this.$toast.success('认证成功')
            await SoldiersCertification({
              userId: getItem('userInfo').id
            }).then((res) => {
              if (res.code == 200) {
                this.approve = res.result
                this.identity = res.result?.card
                this.$store.commit('user/setSoldierApprove', res.result)
              }
            })
            this.$router.push('/me')
          }
        })
      } else {
        return
      }
    },
    // 返回上一页
    returnHome() {
      if (!this.$store.state.user.soldierApprove) {
        this.$toast.fail('访问其它页面请先完成认证')
      } else {
        this.$router.push('/me')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.van-field{
  font-size: 20px !important;
}
.van-button{
  font-size: 20px !important;
}
.approve-container {
  .succeed {
    border: 2px solid #10955b !important;
  }
  .error {
    border: 2px solid #ff0000 !important;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
  .approve-content {
    margin-top: 10px;
    margin-left: 10px;
    width: 355px;
    height: 316px;
    background: #ffffff;
    border-radius: 4px;
  }
  .van-form {
    margin-top: 25px;
  }
  .personal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      border: 2px solid rgb(255, 255, 255);
      margin-top: 20px;
      width: 68px;
      height: 68px;
      border-radius: 68px;
    }
    span {
      margin-top: 10px;
      height: 25px;
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #12151b;
      letter-spacing: 0px;
    }
  }
}
</style>
