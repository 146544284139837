<template>
 <div>
<myApproveNormal v-if="!$store.state.user.elderModel"/>
<myApproveElder v-else/>
 </div>
</template>

<script>
import myApproveNormal from './my-approve-normal.vue'
import myApproveElder from './my-approve-elder.vue'
 export default {
   name: '',
   components: {
     myApproveNormal,
     myApproveElder

   },
   data () {
     return {

     }
   },
   computed: {

   },
   created() {

   },
   mounted() {

   },
   methods: {

   }
 }
</script>

<style  scoped>

 
</style>
